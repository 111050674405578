import { Routes, Route } from "react-router-dom";
import Login from "./pages/Login";
import Signup from "./pages/Signup";
import {AccessTokenProvider} from './Context/AccessTokenContext';
import Admin from "./pages/Admin";

function App() {
  return (
    <AccessTokenProvider>
      <Routes>
        <Route path="/admin/signup" element={<Signup />} />
        <Route path="/admin/login" element={<Login />} />
        <Route path="/admin" element={<Admin />} />

        <Route path="*" element={ <main style={{ padding: "1rem" }}> <p>Page Not Found!</p> </main>}/>
      </Routes>
    </AccessTokenProvider>
  );
}

export default App;
