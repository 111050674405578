import {createContext, useContext, useState } from "react";

const AccessTokenContext = createContext();
const SetAccessTokenContext = createContext();

export function useAccessToken() {
  return useContext(AccessTokenContext);
}

export function useSetAccessToken() {
  return useContext(SetAccessTokenContext);
}

export function AccessTokenProvider({children}) {
  const [accessToken, setAccessToken] = useState(null);

  return (
    <AccessTokenContext.Provider value={accessToken}>
      <SetAccessTokenContext.Provider value={setAccessToken}>
        {children}
      </SetAccessTokenContext.Provider>
    </AccessTokenContext.Provider>
  )

}