import React, {useState, useRef} from 'react';
import { useNavigate} from "react-router-dom";
import { BASE_URL } from '../Constants/apiConstants';

const axios = require('axios');

function Signup() {
  /* Navigate */
  const navigate = useNavigate();

  /* Signup component states */
  const [signupError, setSignupError] = useState(null);
  const [signupSuccess, setSignupSuccess] = useState(null);

  /* Input references */
  const enteredFirstName = useRef();
  const enteredLastName = useRef();
  const enteredEmail = useRef();
  const enteredPassword = useRef();


  /* Signup form submission handler */
  const signupSubmissionHandler = async (evntObj) => {
    evntObj.preventDefault();

    setSignupSuccess(null);
    setSignupError(null);

    const firstname = enteredFirstName.current.value.trim(); 
    const lastname = enteredLastName.current.value.trim(); 
    const email = enteredEmail.current.value.trim(); 
    const password = enteredPassword.current.value.trim(); 

    /* Input validations */
    if(!firstname) {
      setSignupError({
        msg: "First name cannot be empty"
      })
      return;
    }

    if(!lastname) {
      setSignupError({
        msg: "Last name cannot be empty"
      })
      return;
    }

    if(!email) {
      setSignupError({
        msg: "Email cannot be empty"
      })
      return;
    }

    if(!password) {
      setSignupError({
        msg: "Password cannot be empty"
      })
      return;
    }


    // Validations passed: make request to create the user
    try {
      await axios({
        method: 'POST',
        url: `${BASE_URL}/api/user`,
        data: {
          firstname: firstname,
          lastname: lastname,
          email: email,
          password: password
        }
      });

      setSignupSuccess({
        msg: 'Signup successfull, you will be redirected to the login page.'
      })
      setTimeout(() => {
        navigate("/admin/login", { replace: true });
      }, 3000)

    } catch(err) {
      let responseObject = err.response.data;
      if(responseObject.error && responseObject.msg === 'email already exists') {
        setSignupError({
          msg: "This email already exists, you can login or try a different one"
        })
      }
    }

    // axios({
    //   method: 'POST',
    //   url: 'http://localhost:5000/api/user',
    //   data: {
    //     firstname: firstname,
    //     lastname: lastname,
    //     email: email,
    //     password: password
    //   }
    // }).then(response => {
    //   setSignupSuccess({
    //     msg: 'Signup successfull, you will be redirected to the login page.'
    //   })
    //   setTimeout(() => {
    //     navigate("/admin/login", { replace: true });
    //   }, 3000)
    // }).catch(err => {
    //   let responseObject = err.response.data;
    //   if(responseObject.error && responseObject.msg === 'email already exists') {
    //     setSignupError({
    //       msg: "This email already exists, you can login or try a different one"
    //     })
    //   } 
    // })

  };


  return (
    <div className="row g-0 app-auth-wrapper">
      <div className="auth-main-col text-center p-5">
        <div className="d-flex flex-column align-content-end">
          <div className="app-auth-body mx-auto">	
          <div className="app-auth-branding mb-4"><a className="app-logo" href="index.html"><img className="logo-icon me-2" src="/assets/images/app-logo.svg" alt="logo" /></a></div>
            <h2 className="auth-heading text-center mb-4">Sign up to Portal</h2>
            {signupError && <div className="alert alert-danger">{signupError.msg}</div>}				
            {signupSuccess && <div className="alert alert-success">{signupSuccess.msg}</div>}				
            <div className="auth-form-container text-start mx-auto">
              <form className="auth-form auth-signup-form" onSubmit={signupSubmissionHandler}>         
                <div className="mb-3">
                  <label className="sr-only" htmlFor="signup-firstname">First Name</label>
                  <input ref={enteredFirstName} id="signup-firstname" name="signup-firstname" type="text" className="form-control" placeholder="First name" required="required" />
                </div>
                <div className="mb-3">
                  <label className="sr-only" htmlFor="signup-lastname">First Name</label>
                  <input ref={enteredLastName}  id="signup-lastname" name="signup-lastname" type="text" className="form-control" placeholder="Last name" required="required" />
                </div>
                <div className="mb-3">
                  <label className="sr-only" htmlFor="signup-email">Your Email</label>
                  <input ref={enteredEmail} id="signup-email" name="signup-email" type="email" className="form-control" placeholder="Email" required='required' />
                </div>
                <div className="mb-3">
                  <label className="sr-only" htmlFor="signup-password">Password</label>
                  <input ref={enteredPassword} id="signup-password" name="signup-password" type="password" className="form-control" placeholder="Create a password" required="required" />
                </div>                  
                <div className="text-center">
                  <button type="submit" className='btn app-btn-primary' /* className={`${signupClasses.btn}`} */>Sign Up</button>
                </div>
              </form>
              
              <div className="auth-option text-center pt-5">Already have an account? {/* <Link to='/admin/login'><span className="text-link" >Log in</span></Link> */}</div>
            </div>	      
          </div>
        </div>  
      </div>
    </div>
  )
}

export default Signup