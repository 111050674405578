import React, {useState, useEffect} from 'react'
import AdminLayout from '../layouts/AdminLayout'
import {useAccessToken, useSetAccessToken} from '../Context/AccessTokenContext'
import { useNavigate } from "react-router-dom";
import { BASE_URL } from '../Constants/apiConstants';

const axios = require('axios');

function Admin() {
  
  /* Admin component states */
  const [currentAdmin, setCurrentAdmin] = useState(null);
  
  
  /* Contexts */
  const accessToken = useAccessToken();
  const setAccessToken = useSetAccessToken();


  /* Navigate */
  const navigate = useNavigate();

  /* Effects */
  useEffect(() =>{
    if(accessToken) {
      // Make request to get the current admin
      (async function() {
        try {
          let axiosResponse = await axios({
            method: 'GET',
            url: `${BASE_URL}/api/user`,
            withCredentials: true,
            headers: {'Authorization': `Bearer ${accessToken}`}
          });

          setCurrentAdmin(axiosResponse.data.data);
        } catch(err) {
          console.log(err);
          
          // Access token is invalid
          if(err.response.data.msg === 'Access token invalid') {
            setAccessToken(null);
            return;
          }

          // Some other error: naviagte to login (+ call logout)
          localStorage.clear();
          navigate('/admin/login', {replace: true});
        }

      })();
    }

    if(!accessToken) {
      // Make request to get new access token
      (async function() {
        try {
          let axiosResponse = await axios({
            method: 'GET',
            url: `${BASE_URL}/token`,
            withCredentials: true
          });

          const newAccessToken = axiosResponse.data.data.accessToken;
          setAccessToken(newAccessToken); 
        } catch(err) {
          // Could not get  token: naviagate to login page
          localStorage.clear();
          navigate('/admin/login', {replace: true});
        }
      })();
    }

  }, [accessToken]);
  


  const logoutHandler = async () => {
    try {
      await axios({
        method: 'GET',
        url: `${BASE_URL}/logout`,
        withCredentials: true,
      })
      setAccessToken(null);
      localStorage.clear();
    } catch (err) {
      console.log(err);
    }
  }


  return (
    <div>
      {
        currentAdmin && 
        <AdminLayout onLogout={logoutHandler} currentAdmin={currentAdmin}>
          Page Content
        </AdminLayout>
      }
    </div>
  )
}

export default Admin