import {useNavigate, Link} from "react-router-dom";
import React, {useRef, useState, useEffect} from 'react';
import {useAccessToken, useSetAccessToken} from '../Context/AccessTokenContext';
import { BASE_URL } from "../Constants/apiConstants";
import axios from "axios";

function Login() {
  /* Contexts */
  const accessToken = useAccessToken();
  const setAccessToken = useSetAccessToken();
  

  /* Navigate */
  const navigate = useNavigate();


  /* Login component states */
  const [loginError, setLoginError] = useState(null);
  

  /* Input references */
  const enteredEmail = useRef();
  const enteredPassword = useRef();

  /* Effects */
  useEffect(() => {
    if(accessToken || localStorage.getItem('isUserLoggedIn')) {
      navigate('/admin', {replace: true})
    }
  }, [])


  /* Login form submission handler */
  const loginSubmissionHandler = async(eventObj) => {
    eventObj.preventDefault();

    setLoginError(null);

    let email = enteredEmail.current.value.trim();
    let password = enteredPassword.current.value.trim();

    if( !email || !password) {
      setLoginError({
        msg: 'All fields are mandatory'
      });
      return;
    }

    // Validation passed: make server request to get access and refresh tokens
    try {
      let axiosResponse = await axios({
        method: 'POST',
        url: `${BASE_URL}/login`,
        data: {
          email,
          password
        },
        withCredentials: true
      });

      // Login successfull, set accessToken and localstorage variable and redirect to admin page
      setAccessToken(axiosResponse.data.data.accessToken);
      localStorage.setItem('isUserLoggedIn', 'true');
      navigate('/admin', {replace: true});

    } catch (err) {
      console.log(err);
      if(err?.response?.data?.msg === 'incorrect password') {
        setLoginError({
          msg: 'The password you entered is incorrect'
        });
        return;
      }

      setLoginError({
        msg: 'There was some error in our application, please try again later or contact our support team.'
      });
    }
  }

  return (
    <div className="row g-0 app-auth-wrapper">
      <div className="auth-main-col text-center p-5">
        <div className="d-flex flex-column align-content-end">
          <div className="app-auth-body mx-auto">	
          <div className="app-auth-branding mb-4"><a className="app-logo" href="index.html"><img className="logo-icon me-2" src="/assets/images/app-logo.svg" alt="logo" /></a></div>
            <h2 className="auth-heading text-center mb-5">Log in to Portal</h2>
            {loginError && <div className="alert alert-danger">{loginError.msg}</div>}
            <div className="auth-form-container text-start">
              <form className="auth-form login-form" onSubmit={loginSubmissionHandler}>         
                <div className="email mb-3">
                  <label className="sr-only" htmlFor="signin-email">Email</label>
                  <input ref={enteredEmail} id="signin-email" name="signin-email" type="email" className="form-control signin-email" placeholder="Email address" required='required' />
                </div>
                <div className="password mb-3">
                  <label className="sr-only" htmlFor="signin-password">Password</label>
                  <input ref={enteredPassword} id="signin-password" name="signin-password" type="password" className="form-control signin-password" placeholder="Password" required="required" />
                  <div className="extra mt-3 row justify-content-between">
                    <div className="col-6">
                      <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="RememberPassword" />
                        <label className="form-check-label" htmlFor="RememberPassword">
                        Remember me
                        </label>
                      </div>
                    </div>
                    <div className="col-6">
                      <div className="forgot-password text-end">
                        <a href="reset-password.html">Forgot password?</a>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="text-center">
                  <button type="submit" className="btn app-btn-primary w-100 theme-btn mx-auto">Log In</button>
                </div>
              </form>
              
              <div className="auth-option text-center pt-5">No Account? Sign up <Link to={'/admin/signup'}><span className="text-link" >here</span></Link>.</div>
            </div>	
          </div>
        </div>   
      </div>
    </div>
  )
}

export default Login